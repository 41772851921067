.lds-gear {
    width          : 100vw;
    height         : 100vh;
    display        : flex;
    justify-content: center;
    align-items    : center;

    position  : absolute;
    z-index   : 50;
    background: #101010;
    opacity   : 0.7;
}