.item-center {
    display        : flex;
    align-items    : center;
    justify-content: center;
}

.mg-button {
    padding        : 2px 15px;
    border-radius  : 3px;
    text-decoration: none;
}