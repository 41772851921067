* {
  margin    : 0;
  padding   : 0;
  box-sizing: border-box;

}

html {
  font-size: 62.5%;
}

body {
  margin                 : 0;
  font-family            : 'Roboto', sans-serif !important;
  -webkit-font-smoothing : antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size              : 1.4rem;
  line-height            : 1.5;
  color                  : #555;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

img {
  max-width: 100%;
}

.btn {
  border-radius: 4px;
  padding      : 5px 20px;
  min-width    : 100px;
  font-size    : 16px;
  line-height  : 24px;
  font-weight  : 700;

  &:hover {
    opacity: 0.9;
  }

  &:disabled {
    cursor : pointer;
    opacity: 0.9;
  }
}

.item-center {
  display        : flex;
  align-items    : center;
  justify-content: center;
}