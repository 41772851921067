$primary_color   : #FDDB08;
$primary_color_2 : #717172;

$primary_color_blue : #0060AF;
$price_text_color   : #888;
$border_color       :#ccc;

$white_color : #ffffff;
$dark_color  : #000000;

$bg_menu :#999595;
$bg_text :#FDDB08;


$red_color     : #ed1b24;
$bg_hover_menu : #f4efef;
$border_radius : 5px;


// $themes : (dark: (n_modal_header_background: #fff) //START

//     light : (n_modal_header_background: #fff));