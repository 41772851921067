@import "../../../styles/common";


.home-scale {
    margin-top   : 80px;
    margin-bottom: 45px;

    .vision-porcelain {
        .about3 {

            .col-about3 {
                .i-title {
                    color         : $price_text_color;
                    font-family   : 'Roboto Condensed', sans-serif;
                    font-weight   : 700;
                    text-transform: uppercase;
                    font-size     : 30px;
                    line-height   : 30px;
                    margin-bottom : 20px;
                    padding-bottom: 5px;
                    position      : relative;

                    .text-title {
                        font-weight: bold;
                    }
                }

                .img-icon {
                    width        : 150px;
                    height       : 150px;
                    border-radius: 50%;
                    border       : 3px solid $primary_color;
                    margin       : 0 auto;
                    margin-bottom: 20px;

                    i {
                        font-size: 70px;
                        color    : $price_text_color;
                    }
                }

                .content {
                    font-size  : 16px;
                    line-height: 24px;
                    text-align : justify;
                }
            }

            .wrap-bg {
                width : 100%;
                height: 100%;

                .bg-color {
                    background     : $bg_menu;
                    width          : 100%;
                    height         : 100%;
                    padding        : 40px;
                    display        : flex;
                    flex-direction : column;
                    justify-content: space-between;

                    span {
                        display    : block;
                        text-align : center;
                        font-size  : 33px;
                        line-height: 33px;
                        color      : $primary_color;
                        font-weight: 400;
                        font-family: 'Roboto Condensed', sans-serif;
                    }

                    .bg-logo {
                        img {
                            width: 50%;
                        }
                    }
                }
            }
        }
    }

    .system-company {
        margin-top     : 80px;
        background     : url("../../../assets/images/home/home_scale/bg_khoxuong.png") no-repeat;
        background-size: cover;
        margin-bottom  : 10px;

        .container {
            height: 100%;
        }

        .boxBig {
            padding: 40px 0px;

            >div {
                background-color: $white_color;

                .sub {
                    .content-sub {
                        margin: 60px 30px 50px 30px;

                        .animate_4 {
                            color    : $dark_color;
                            font-size: 30px;
                        }

                        .introduce-company {
                            font-size    : 16px;
                            line-height  : 24px;
                            font-weight  : 400;
                            margin-bottom: 20px;
                        }

                        .block-content {
                            display       : flex;
                            flex-direction: column;
                            gap           : 10px;

                            .i-item {
                                display: flex;
                                gap    : 20px;

                                .icon {
                                    margin-top: 5px;

                                    i {
                                        font-size: 40px;
                                        color    : $price_text_color;
                                    }
                                }

                                .text {
                                    font-size  : 16px;
                                    line-height: 24px;
                                    font-weight: 400;
                                }
                            }
                        }
                    }

                    .content-img {
                        height   : 100%;
                        width    : 100%;
                        max-width: 560px;

                        .slick-slider {
                            height: 100%;
                            width : 100%;

                            .slick-list {
                                height: 100%;
                                width : 100%;

                                .slick-track {
                                    height: 100%;
                                    width : 100%;

                                    .slick-slide {
                                        height: 100%;
                                        width : 100%;

                                        >div {
                                            height: 100%;
                                            width : 100%;

                                        }
                                    }

                                    img {
                                        width : 100% !important;
                                        height: 100% !important;
                                    }


                                    .khoxuong-item {
                                        height: 100%;
                                        width : 100%;
                                        border: 3px solid transparent;

                                        .wrap-khoxuong-item {
                                            padding      : 20px;
                                            background   : #f9f9f9;
                                            // border    : 2px solid #ececec;
                                            height       : 100%;
                                            width        : 100%;

                                            .khoxuong-image {
                                                height  : 100%;
                                                width   : 100%;
                                                position: relative;


                                            }
                                        }
                                    }
                                }
                            }

                            .slick-dots {
                                bottom: -20px;

                                li {
                                    button {
                                        &::before {
                                            font-size: 10px;
                                        }
                                    }
                                }

                                .slick-active {
                                    button {
                                        &::before {
                                            color: $white;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }

            }
        }
    }

    .list-img-khoxuong {
        display  : flex;
        width    : 100%;
        flex-wrap: wrap;

        .item-img {
            width   : calc(100% / 6);
            position: relative;
            cursor  : pointer;

            &::after {
                content   : '';
                position  : absolute;
                left      : 0;
                top       : 0;
                right     : 0;
                bottom    : 0;
                background: rgba(0, 0, 0, .5);
                opacity   : 1;
                transition: all .4s;
            }

            &:hover {
                &::after {
                    opacity: 0;
                }
            }
        }
    }
}



// từ 767px trở xuống
@include media-breakpoint-down(sm) {
    .home-scale {
        margin-top   : 80px;
        margin-bottom: 45px;

        .vision-porcelain {
            .about3 {
                gap: 20px;
            }
        }


        .boxBig {
            padding: 40px 0px;

            >div {
                background-color: $white_color;

                .sub {
                    .content-img {
                        max-width: 500px !important;
                    }
                }
            }

            .list-img-khoxuong {
                display: flex;
                width  : 100%;

                .item-img {
                    width: calc(100% / 3);
                }
            }
        }
    }
}

// từ 575.98px trở xuống
@include media-breakpoint-down(xs) {
    .home-scale {
        .boxBig {
            padding: 40px 0px;

            >div {
                background-color: $white_color;

                .sub {
                    .content-img {
                        max-width: 400px !important;
                    }
                }
            }
        }
    }
}



// từ 991px trở xuống
@include media-breakpoint-down(md) {}

// từ 1199px trở xuống
@include media-breakpoint-down(lg) {}