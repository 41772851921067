@import "../../styles/common";

.login {
    display        : flex;
    align-items    : center;
    justify-content: center;

    .form-login {
        background      : #fff;
        max-width       : 400px;
        width           : 100%;
        // border       : 1px solid #ddd;
        padding         : 50px 25px;
        border-radius   : 10px;
        min-width       : 400px;

        .form-group {
            margin-bottom: 1rem;
            position     : relative;


            .icon-show-pass {
                position: absolute;
                top     : 40px;
                right   : 10px;
                cursor  : pointer;
            }

            label {
                display      : inline-block;
                margin-bottom: 10px;
            }

            .form-control-input {
                display         : block;
                width           : 100%;
                height          : 40px;
                padding         : 5px 10px;
                font-weight     : 400;
                color           : #495057;
                background-color: #fff;
                background-clip : padding-box;
                border          : 1px solid #dfdfe1;
                border-radius   : 5px;
                transition      : border-color .15s ease-in-out, box-shadow .15s ease-in-out;

                &:focus-visible {
                    border : 1px solid $primary_color;
                    outline: none;
                }
            }
        }

        .block-image {
            width : 150px;
            height: 150px;

            .pre-image {
                object-fit: cover;
                width     : 100%;
                height    : auto;
            }
        }

        .btn-submit {
            color     : $primary_color;
            background: $bg_menu;
            border    : 0;
            min-width : 100px;
            height    : 40px;

            &:hover {
                color: #fff;
            }
        }
    }
}