@import "../../styles/common";

.product-collections {
    .content {
        .content-wrap {
            background: url("../../assets/images/product_collections/collection-banner-header.jpg");

            .collection-description {
                position     : relative;
                margin-bottom: 30px;

                &::after {
                    content : '';
                    position: absolute;
                    top     : 0;
                    left    : 0;
                    right   : 0;
                    bottom  : 0;
                    z-index : 1;
                }

                .container {
                    z-index : 2;
                    position: relative;

                    h3 {
                        color      : #444;
                        font-weight: 700;
                        line-height: 1.5;
                        margin     : 0 0 20px 0;
                        font-size  : 20px;

                        span {
                            color: #e74c3c;
                        }
                    }

                    .rte-col3 {
                        display: flex;

                        .title-main {
                            color    : #e74c3c;
                            display  : flex;
                            gap      : 10px;
                            font-size: 22px;

                            .active {
                                cursor: pointer;
                            }
                        }

                        .title-child {
                            display  : flex;
                            gap      : 10px;
                            font-size: 20px;
                            color    : #000000;

                            .active {
                                cursor: pointer;
                            }
                        }
                    }
                }

            }
        }
    }

    .list-product {
        margin-top: 20px;

        .product {
            position: relative;
            float   : left;
            margin  : 0 20px 20px 0;

            .product-image {
                position: relative;
                width   : 100%;
                height  : 100%;
                overflow: hidden;

                .block-image {
                    display   : block;
                    width     : 100%;
                    height    : 100%;
                    transition: all .5s ease;
                    cursor    : pointer;

                    img {
                        width     : 100%;
                        height    : 250px;
                        object-fit: cover;
                    }
                }

                .block-image2 {
                    display: none;
                }


                &:hover {
                    .block-image1 {
                        display: none;
                    }

                    .block-image2 {
                        display: block;
                    }
                }
            }

            .product-content {
                padding  : 15px 0 5px;
                font-size: 14px;

                .product-title {
                    h3 {
                        color      : #444;
                        font-weight: 600;
                        line-height: 1.5;
                    }
                }

                .product-desc {
                    overflow          : hidden;
                    display           : -webkit-box;
                    -webkit-line-clamp: 3;
                    -webkit-box-orient: vertical;
                }

                .view-all {
                    margin-top: 20px;
                    display   : block;
                    width     : 100%;

                    a {
                        background: $bg_menu;
                        color     : $primary_color;
                    }
                }
            }
        }
    }
}


// từ 767px trở xuống
@include media-breakpoint-down(sm) {
    .product-collections {
        .content {
            .content-wrap {
                .collection-description {
                    .container {
                        .rte-col3 {
                            flex-direction: column;
                        }
                    }
                }
            }
        }
    }
}