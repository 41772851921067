@import "../../../styles/common";

.tool-phone-zalo-chat {
    position: fixed;
    top     : 56%;
    right   : 0px;
    z-index : 9999;

    .phonering-alo-phone,
    .zaloring-alo-phone {
        width : 65px;
        height: 65px;
    }

    .phonering-alo-phone,
    .zaloring-alo-phone,
    .mess-facebook,
    .fanpage-fb,
    .youtube-recomend {
        position  : relative;
        margin-top: 5px;

        .phonering-alo-ph-circle-fill {
            width                   : 70px;
            height                  : 70px;
            position                : absolute;
            background-color        : #000;
            border-radius           : 100% !important;
            border                  : 2px solid transparent;
            -webkit-animation       : phonering-alo-circle-fill-anim 2.3s infinite ease-in-out;
            animation               : phonering-alo-circle-fill-anim 2.3s infinite ease-in-out;
            transition              : all .5s;
            -webkit-transform-origin: 50% 50%;
            -ms-transform-origin    : 50% 50%;
            transform-origin        : 50% 50%;

        }

        .alo-ph-img-circle {
            width                   : 50px;
            height                  : 50px;
            top                     : 10px;
            left                    : 10px;
            position                : absolute;
            border-radius           : 100% !important;
            border                  : 2px solid transparent;
            -webkit-animation       : phonering-alo-circle-img-anim 1s infinite ease-in-out;
            animation               : phonering-alo-circle-img-anim 1s infinite ease-in-out;
            -webkit-transform-origin: 50% 50%;
            -ms-transform-origin    : 50% 50%;
            transform-origin        : 50% 50%;
        }

        .img-phonering {
            background      : url("../../../assets/images/phone_zalo_chat/icon-phone.png") no-repeat center center;
            background-color: $red_color;
        }

        .img-zaloring {
            background      : url("../../../assets/images/phone_zalo_chat/zalo.png") no-repeat center center;
            background-color: #4285f4;
        }
    }

    .mess-facebook {
        .fb-btn {
            display              : block;
            margin               : 0px auto;
            position             : relative;
            background           : url("../../../assets/images/phone_zalo_chat/messenger-chat.png")center no-repeat #4285f4;
            width                : 50px;
            height               : 50px;
            text-align           : center;
            border               : 0;
            outline              : 0;
            border-radius        : 60px;
            -webkit-border-radius: 60px;
            -moz-border-radius   : 60px;
            -ms-border-radius    : 60px;
            -o-border-radius     : 60px;
            /* box-shadow        : 0 1px 6px rgb(0 0 0 / 6%), 0 2px 32px rgb(0 0 0 / 16%); */
            -webkit-transition   : box-shadow .2s ease;
            background-size      : 80%;
            transition           : all .2s ease-in-out;
        }
    }

    .fanpage-fb {
        .fb-btn {
            display              : block;
            margin               : 0px auto;
            position             : relative;
            background           : url("../../../assets/images/phone_zalo_chat/facebook-icon.svg")center no-repeat #4285f4;
            width                : 50px;
            height               : 50px;
            text-align           : center;
            border               : 0;
            outline              : 0;
            border-radius        : 60px;
            -webkit-border-radius: 60px;
            -moz-border-radius   : 60px;
            -ms-border-radius    : 60px;
            -o-border-radius     : 60px;
            /* box-shadow        : 0 1px 6px rgb(0 0 0 / 6%), 0 2px 32px rgb(0 0 0 / 16%); */
            -webkit-transition   : box-shadow .2s ease;
            background-size      : 80%;
            transition           : all .2s ease-in-out;
        }
    }

    .youtube-recomend {
        .youtube-btn {
            display              : block;
            margin               : 0px auto;
            position             : relative;
            background           : url("../../../assets/images/phone_zalo_chat/icon_youtube.png")center no-repeat $red_color;
            width                : 50px;
            height               : 50px;
            text-align           : center;
            border               : 0;
            outline              : 0;
            border-radius        : 60px;
            -webkit-border-radius: 60px;
            -moz-border-radius   : 60px;
            -ms-border-radius    : 60px;
            -o-border-radius     : 60px;
            /* box-shadow        : 0 1px 6px rgb(0 0 0 / 6%), 0 2px 32px rgb(0 0 0 / 16%); */
            -webkit-transition   : box-shadow .2s ease;
            background-size      : 80%;
            transition           : all .2s ease-in-out;
        }
    }

    .hotline-phone-ring-wrap {
        position: absolute;
        right   : 0px;

        &.hotline-phone-ring-wrap-1 {
            top: -120px;
        }

        &.hotline-phone-ring-wrap-2 {
            top  : -60px;
            right: 0px;
        }

        .phonering-alo-phone {
            position: absolute;
            top     : -65px;
            right   : 147px;
            z-index : 9;

            .phonering-alo-ph-circle-fill {
                background-color: rgba(230, 8, 8, 0.7);
            }
        }

        .hotline-bar {
            position          : absolute;
            background        : rgba(230, 8, 8, 0.75);
            height            : 40px;
            width             : 200px;
            line-height       : 40px;
            border-radius     : 3px;
            padding           : 0 10px;
            background-size   : 100%;
            cursor            : pointer;
            transition        : all 0.8s;
            -webkit-transition: all 0.8s;
            z-index           : 1;
            box-shadow        : 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.1);
            border-radius     : 50px !important;
            /* width          : 175px !important; */
            right             : 0px;
            top               : -45px;

            a {
                color          : #fff;
                text-decoration: none;
                font-size      : 15px;
                font-weight    : bold;
                text-indent    : 50px;
                display        : block;
                letter-spacing : 1px;
                line-height    : 40px;
                font-family    : Arial;
            }
        }
    }

    .zaloring-alo-phone {
        .phonering-alo-ph-circle-fill {
            background-color: rgba(17, 130, 252, .8);
        }
    }
}


@keyframes phonering-alo-circle-img-anim {
    0% {
        -webkit-transform: rotate(0) scale(1) skew(1deg);
        transform        : rotate(0) scale(1) skew(1deg);
    }

    10% {
        -webkit-transform: rotate(-25deg) scale(1) skew(1deg);
        transform        : rotate(-25deg) scale(1) skew(1deg);
    }

    20% {
        -webkit-transform: rotate(25deg) scale(1) skew(1deg);
        transform        : rotate(25deg) scale(1) skew(1deg);
    }

    30% {
        -webkit-transform: rotate(-25deg) scale(1) skew(1deg);
        transform        : rotate(-25deg) scale(1) skew(1deg);
    }

    40% {
        -webkit-transform: rotate(25deg) scale(1) skew(1deg);
        transform        : rotate(25deg) scale(1) skew(1deg);
    }

    50% {
        -webkit-transform: rotate(0) scale(1) skew(1deg);
        transform        : rotate(0) scale(1) skew(1deg);
    }

    100% {
        -webkit-transform: rotate(0) scale(1) skew(1deg);
        transform        : rotate(0) scale(1) skew(1deg);
    }
}


@keyframes phonering-alo-circle-fill-anim {
    0% {
        -webkit-transform: rotate(0) scale(.7) skew(1deg);
        transform        : rotate(0) scale(.7) skew(1deg);
        opacity          : .2;
    }

    50% {
        -webkit-transform: rotate(0) scale(1) skew(1deg);
        transform        : rotate(0) scale(1) skew(1deg);
        opacity          : .2;
    }

    100% {
        -webkit-transform: rotate(0) scale(.7) skew(1deg);
        transform        : rotate(0) scale(.7) skew(1deg);
        opacity          : .2;
    }
}