.row {
    &.gutters-0 {
        margin-right: -0px;
        margin-left : -0px;

        >.col,
        >[class*="col-"] {
            padding-right: 0px;
            padding-left : 0px;
        }
    }

    &.gutters-5 {
        margin-right: -5px;
        margin-left : -5px;

        >.col,
        >[class*="col-"] {
            padding-right: 5px;
            padding-left : 5px;
        }
    }

    &.gutters-10 {
        margin-right: -10px;
        margin-left : -10px;

        >.col,
        >[class*="col-"] {
            padding-right: 10px;
            padding-left : 10px;
        }
    }
}