@import "../../../styles/common";


.our-customer {
    margin-top: 50px;

    .section-heading {
        padding-top  : 30px;
        margin-bottom: 50px;

        .section-title {
            margin-bottom: 0;
            font-size    : 26px;
            color        : $dark_color;

            p {
                margin-bottom: 15px;
            }
        }
    }

    .block-brand {
        margin-bottom: 15px;

        .img-brand {
            width        : 100%;
            position     : relative;
            display      : block;
            width        : 100%;
            height       : auto;
            border       : 2px dashed $bg_menu;
            padding      : 5px;
            border-radius: $border_radius;

            img {
                position  : relative;
                display   : block;
                width     : 100%;
                height    : auto;
                border    : none;
                background: none;
                height    : 80px;
            }
        }
    }
}




@include media-breakpoint-down(sm) {}


// từ 991px trở xuống
@include media-breakpoint-down(md) {}