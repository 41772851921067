@import "../../styles/common";

.footer {
    background-image : url("../../assets/images/footer/footer-bg.png");
    background-size  : cover;
    background-repeat: no-repeat;
    color            : $white_color;
    margin-top       : 20px;

    a {
        &:hover {
            color: $primary_color;
        }
    }

    .footer-widgets-wrap {
        position: relative;
        padding : 30px 0 20px 0;

        .column-footer {
            margin-top: 30px;

            .widget {
                .section-logo {
                    .block-logo-image {
                        img {
                            height: 175px;
                        }
                    }
                }

                .section-company-name {
                    font-size  : 16px;
                    font-weight: 700;
                    color      : $primary_color;
                    margin-top : 13px;
                }

                .section-title {
                    font-size     : 16px;
                    font-weight   : 700;
                    padding-bottom: 10px;
                    border-bottom : 2px solid $primary_color;
                    color         : $primary_color;
                }

                .section-body {
                    margin-top: 10px;
                    color     : #f1f1f1;

                    .section-body-wrap {
                        flex-direction: column;
                        display       : flex;
                        gap           : 10px;

                        .section-body-label-value {
                            display: flex;

                            .value {
                                color: $white_color;
                            }
                        }
                    }
                }
            }
        }


    }

    .copyrights {
        background-color: #000000;
        color           : $white_color;
        text-shadow     : 1px 1px 1px rgba(0, 0, 0, 0.1);
        padding         : 20px 0;
        font-size       : 14px;
        line-height     : 1.8;
    }
}