@import "../../../styles/common";


.home-banner {

    .section-heading {
        padding-top  : 30px;
        margin-bottom: 50px;

        .section-title {
            margin-bottom: 0;
            font-size    : 26px;
            color        : $dark_color;

            p {
                margin-bottom: 15px;
            }
        }
    }

    .item-feature-box {
        margin-bottom: 20px;

        .wrap-feature-box {
            padding         : 15px;
            box-shadow      : 0px 0px 20px -10px rgba(0, 0, 0, 0.781);
            background-color: rgba(228, 228, 228, 0);
            border-radius   : $border_radius;
            position        : relative;
            width           : 100%;
            height          : 100%;

            .feature-box {
                padding: 0;
            }

            position: relative;

            .fbox-media {
                position: relative;
                display : block;
                width   : 100%;
                height  : auto;
                margin  : 0 0 25px;

                a {
                    position: relative;
                    display : block;
                    width   : 100%;
                    height  : auto;

                    img {
                        position  : relative;
                        display   : block;
                        width     : 100%;
                        height    : auto;
                        border    : none;
                        background: none;
                        transition: 0.5s;
                        width     : 333px;
                        height    : 250px;

                        &:hover {
                            transform: scale(1.1);
                        }
                    }
                }
            }

            .fbox-desc {
                .title {
                    font-weight  : 700;
                    margin-bottom: 10px;
                }

                h3 {
                    .subtitle {
                        display       : block;
                        margin-top    : 5px;
                        color         : #444;
                        font-weight   : 300;
                        text-transform: none;
                        font-size     : 16px;
                    }
                }
            }

            &:hover {
                .fbox-media {
                    a {
                        img {
                            transform: scale(1.05);
                        }
                    }
                }

                .fbox-desc {

                    .title {
                        color: $red_color;
                    }
                }
            }
        }
    }

    .view-all {
        .btn {
            margin-top: 20px;
            background: $bg_menu;
            color     : $primary_color;
        }
    }
}




@include media-breakpoint-down(sm) {
    .home-banner {

        .section-heading {

            .section-title {

                p {}
            }
        }

        .item-feature-box {

            .wrap-feature-box {

                .fbox-media {
                    a {
                        img {

                            width : 100%;
                            height: 250px;

                            &:hover {}
                        }
                    }
                }


            }
        }

    }

}


// từ 991px trở xuống
@include media-breakpoint-down(md) {}