@import "../../styles/common";

.header {
    // @include themify($themes) {
    background   : #f6eba9;
    border       : none;
    // position  : fixed;
    // right     : 0;
    // left      : 0;
    // z-index   : 1030;
    // top       : 0;
    // filter    : drop-shadow(0 1px 4px rgba(70, 75, 80, .2));

    .container-header {
        display      : flex;
        height       : 100%;
        margin       : 0 auto;
        // min-height: 110px;

        .navbar-logo {

            .div-img-logo {
                padding: 15px 0px;
            }

            .img-logo {
                // width : 100px;
                height: 90px;
            }

            .header-icon-bars {
                display            : none;
                animation-duration : .60s;
                animation-fill-mode: both;
                transition         : all 500ms cubic-bezier(1, 0, 0, 1);
                position           : absolute;
                right              : 10px;
                z-index            : 200;
                top                : 50%;
                transform          : translateY(-50%);
                font-size          : 25px;
                color              : $primary_color;
                cursor             : pointer;

                i {
                    border-radius: 4px;
                    padding      : 5px 8px;
                    border       : 2px solid $primary_color;
                }
            }
        }

        .header-extras {
            height: 100%;

            .extra-item {

                width  : 100%;
                height : 100%;
                display: flex;
                padding: 15px 0px;

                .extra-wrap {
                    display: flex;

                    .extra-item-icon {
                        i {
                            width      : 36px !important;
                            height     : 36px !important;
                            font-size  : 36px;
                            line-height: 36px !important;
                            color      : $primary_color_2;
                        }
                    }

                    .extra-item-content {
                        padding-left  : 15px;
                        font-weight   : 300;
                        font-size     : 12px;
                        line-height   : 16px;
                        color         : $price_text_color;
                        text-transform: uppercase;
                        letter-spacing: 1px;
                        padding-top   : 25px;

                        .info-content {
                            color         : $red_color;
                            font-weight   : 600;
                            text-transform: none;
                            font-size     : 16px;
                            line-height   : 24px;
                            letter-spacing: 0;
                        }
                    }
                }

                .extra-email,
                .extra-time {
                    padding-left: 20px;
                    border-left : 1px solid rgb(238, 238, 238);
                }
            }
        }
    }


    .header-wrap {
        background   : $bg_menu;
        border-bottom: 2px solid $primary_color;

        &.sticky-header {
            z-index                    : 199;
            position                   : fixed;
            top                        : 0 !important;
            left                       : 0;
            width                      : 100%;
            background                 : $bg_menu;
            box-shadow                 : 0 0 10px rgba(9, 9, 9, 0.1);
            -webkit-backface-visibility: hidden;
        }

        .menu {
            .nav-menu {
                display: flex;

                .nav-menu-item {
                    padding       : 15px 15px;
                    display       : block;
                    color         : $white_color;
                    font-weight   : bold;
                    font-size     : 13px;
                    letter-spacing: 1px;
                    text-transform: uppercase;
                    cursor        : pointer;

                    &.active {
                        >a {
                            color: $primary_color;
                        }
                    }

                    &.nav-menu-submenu {
                        position: relative;
                    }

                    .sub-menu {
                        display               : none;
                        position              : absolute;
                        width                 : 220px;
                        background-color      : $bg_menu;
                        // box-shadow      : 0px 13px 42px 11px rgb(0 0 0 / 5%);
                        // border             : 1px solid #EEE;
                        border-top            : 2px solid $primary_color;
                        height                : auto;
                        z-index               : 199;
                        top                   : 45px;
                        left                  : 0;
                        margin                : 0;
                    }

                    &:hover {
                        >a {
                            color: $primary_color;
                        }

                        .sub-menu {
                            display: block;
                        }
                    }


                    .sub-menu-item {
                        position: relative;

                        &:hover {
                            background-color: $bg_menu;

                            >a {
                                font-weight : bold !important;
                                padding-left: 25px;
                                transition  : all 0.5s ease-out;
                                color       : $primary_color;
                            }
                        }

                        >a {
                            padding           : 0px 20px;
                            font-size         : 12px;
                            font-weight       : 600;
                            height            : 42px !important;
                            line-height       : 42px !important;
                            // color          : #666;
                            padding-top       : 0 !important;
                            padding-bottom    : 0 !important;
                            border            : 0 !important;
                            letter-spacing    : 0;
                            display           : flex;
                            justify-content   : space-between;

                        }

                        .sub-menu-lv2 {
                            display            : none;
                            position           : absolute;
                            width              : 220px;
                            background-color   : $bg_menu;
                            // border          : 1px solid #EEE;
                            border-top         : 2px solid $primary_color;
                            height             : auto;
                            z-index            : 199;
                            top                : -2px;
                            left               : 215px;
                            margin             : 0;

                            .sub-menu-item-lv2 {

                                &:hover {
                                    >a {
                                        font-weight : bold !important;
                                        padding-left: 25px;
                                        transition  : all 0.5s ease-out;
                                        color       : $primary_color;
                                    }
                                }

                                >a {
                                    padding           : 0px 20px;
                                    font-size         : 12px;
                                    font-weight       : 600;
                                    height            : 42px !important;
                                    line-height       : 42px !important;
                                    // color          : #666;
                                    padding-top       : 0 !important;
                                    padding-bottom    : 0 !important;
                                    border            : 0 !important;
                                    letter-spacing    : 0;
                                    display           : flex;
                                    justify-content   : space-between;
                                }
                            }
                        }

                        &:hover {
                            .sub-menu-lv2 {
                                display: block;
                            }
                        }
                    }
                }
            }
        }
    }

    // }
}


// từ 575.98px trở xuống
@include media-breakpoint-down(xs) {}


// từ 767px trở xuống
@include media-breakpoint-down(sm) {}


// từ 991px trở xuống
@include media-breakpoint-down(md) {
    .header {
        height: 60px;

        .container-header {
            display      : flex;
            height       : 100%;
            margin       : 0 auto;
            // min-height: 110px;

            .navbar-logo {
                height                     : 60px;
                z-index                    : 199;
                position                   : fixed;
                top                        : 0 !important;
                left                       : 0;
                width                      : 100%;
                background-color           : #FFF;
                box-shadow                 : 0 0 10px rgba(9, 9, 9, 0.1);
                -webkit-backface-visibility: hidden;

                .div-img-logo {
                    height : 100%;
                    padding: 0px;

                    .img-logo {
                        // width : 100px;
                        height   : 60px;
                        max-width: 230px;
                    }
                }

                .header-icon-bars {
                    display: block;
                }
            }

            .header-extras {
                display: none;
            }
        }

        .header-wrap {
            display: none;
        }

    }
}