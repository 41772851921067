@import "../../../styles/common";

.list-project {
    .item-project-box {
        margin-bottom: 20px;

        .wrap-project-box {
            padding         : 15px;
            box-shadow      : 0px 0px 20px -10px rgba(0, 0, 0, 0.781);
            background-color: rgba(228, 228, 228, 0);
            border-radius   : $border_radius;
            position        : relative;
            width           : 100%;
            height          : 100%;

            .project-box {
                padding : 0;
                position: relative;
                cursor  : pointer;

                .project-img {
                    position: relative;
                    display : block;
                    width   : 100%;
                    height  : auto;
                    margin  : 0 0 25px;

                    img {
                        position  : relative;
                        display   : block;
                        width     : 100%;
                        height    : auto;
                        border    : none;
                        background: none;
                        transition: 0.5s;
                    }

                }

                .project-desc {
                    .title {
                        font-weight  : 700;
                        margin-bottom: 5px;
                        margin-top   : 0px;
                        padding      : 0;
                        color        : $dark_color;
                        font-size    : 16px;
                        font-weight  : bold;
                    }

                    .date {
                        display      : flex;
                        margin-bottom: 5px;
                        color        : #acacac;
                        font-size    : 12px;

                        .icon-date {
                            margin-right: 3px;
                        }
                    }

                    .des {
                        margin-bottom: 5px;
                        color        : #3e3b3b;
                        font-style   : italic !important;
                    }

                    .hastag {
                        color    : #acacac;
                        font-size: 12px;
                    }
                }


                &:hover {
                    .project-img {
                        img {
                            transform: scale(1.05);
                        }
                    }
                }
            }
        }
    }
}