@import "../../styles/common";

.payment {

    .content {
        margin: 20px 0px;


        .title-page {
            font-family  : 'Noto Serif', serif;
            font-size    : 30px;
            color        : $dark_color;
            margin-bottom: 20px;
        }

        .block-session {
            margin-bottom: 20px;

            .title-session {
                font-size    : 20px;
                color        : $dark_color;
                font-weight  : 700;
                font-family  : 'Noto Serif', serif;
                margin-bottom: 10px;
            }

            .body-session {
                display       : flex;
                flex-direction: column;
                gap           : 10px;
            }
        }
    }
}