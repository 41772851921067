@import "../../styles/common";

.container-home {
    width : 100%;
    height: 100%;

    .slider {
        margin: 0 auto;
        width : 100%;

        .owl-carousel {
            .owl-stage-outer {
                .owl-item {

                    div {
                        .img {
                            width                 : 100%;
                            background-repeat     : no-repeat;
                            background-size       : cover;
                            background-position   : center;
                            // max-width          : 1300px;
                            margin                : 0 auto;
                        }
                    }
                }
            }
        }

        .owl-prev {
            width   : 15px;
            height  : 60px;
            position: absolute;
            top     : 30%;
            left    : 20px;
            display : block !important;
            border  : 0px solid black;
        }

        .owl-next {
            width   : 15px;
            height  : 60px;
            position: absolute;
            top     : 30%;
            right   : 20px;
            display : block !important;
            border  : 0px solid black;
        }

        .owl-prev span,
        .owl-next span {
            transform: scale(1, 6);
            color    : #ccc;
        }

        .arrow {
            &:hover {
                background: #869791;
                color     : #fff;
            }

            &.next {
                right: 10px;
            }

            &.prev {
                left: 10px;
            }

            i {
                font-size: 36px;
            }
        }
    }


    .home {
        margin: 40px 0px;

        .section-heading {


            .section-title {
                position   : relative;
                height     : 60px;
                font-size  : 30px;
                font-family: 'Noto Serif', serif;

                .title {
                    position: relative;

                    &::after {
                        content      : '';
                        position     : absolute;
                        width        : calc(50% - 30px);
                        top          : 50px;
                        left         : 0px;
                        border-bottom: 1px solid $border_color;
                    }

                    &::before {
                        content      : '';
                        position     : absolute;
                        width        : calc(50% - 30px);
                        top          : 50px;
                        right        : 0px;
                        border-bottom: 1px solid $border_color;
                    }
                }

                .extra-box {
                    position           : absolute;
                    transform          : translateX(-50%);
                    top                : 35px;
                    left               : 50%;
                    padding            : 0px 15px;
                    // background-color: $white_color;

                    .img-extra {
                        background     : url('../../assets/images/company/logo.png') no-repeat;
                        width          : 30px;
                        height         : 30px;
                        background-size: 100%;
                    }
                }
            }

            .section-desc {
                margin-top: 10px;
            }
        }
    }


    .content-home {


        .content-wrap {
            padding: 20px 0;

            .home-counter {
                padding : 20px 0px 0px 0px;
                position: relative;

                .container {
                    position: relative;

                    .section-heading {
                        margin-bottom: 40px;

                        .section-title {
                            margin-bottom: 0;
                            font-size    : 40px;
                            color        : $dark_color;
                            font-weight  : 700;
                        }
                    }

                    .wrap-des-company {
                        display: flex;
                        gap    : 20px;
                        width  : 100%;

                        .block-image-conpany {
                            img {
                                width: 100%;
                            }
                        }

                        .block-des {
                            .desc {
                                font-size    : 16px;
                                margin-bottom: 20px;
                            }
                        }
                    }


                    .block-home-counter {
                        height : max-content;
                        padding: 20px;
                        color  : $price_text_color;

                        .home-counter-item {
                            display       : flex;
                            flex-direction: column;

                            .home-counter-number {
                                font-size  : 32px;
                                font-weight: bold;
                            }

                            .home-counter-border {
                                height       : 1px;
                                width        : 50px;
                                display      : inline-block;
                                background   : #333;
                                margin-bottom: 5px;
                            }

                            .home-counter-text {
                                font-size  : 16px;
                                font-weight: bold;
                                display    : inline-block;
                            }
                        }
                    }
                }
            }
        }
    }
}





// từ 991px trở xuống
@include media-breakpoint-down(md) {

    .container-home {
        .content-home {
            .content-wrap {

                .home {
                    .section-heading {
                        .section-title {
                            height   : unset;
                            font-size: 30px !important;

                            .title {
                                &::after {
                                    display: none;
                                }

                                &::before {
                                    display: none;
                                }
                            }

                            .extra-box {
                                display: none;
                            }
                        }
                    }
                }

                .home-counter {
                    padding: 0px 0px 50px 0px;
                    margin : 10px 0px !important;

                    .container {
                        .wrap-des-company {

                            .block-des {}
                        }

                        .block-home-counter {
                            position  : relative;
                            padding   : 0px;
                            background: unset;
                            max-width : unset;
                            bottom    : 0px;

                            .home-counter-item {
                                .home-counter-number {
                                    font-size  : 32px;
                                    font-weight: bold;
                                }

                                .home-counter-border {
                                    display: block;
                                }

                                .home-counter-text {}
                            }
                        }
                    }

                }
            }
        }
    }
}

@include media-breakpoint-down(sm) {
    .container-home {
        .content-home {
            .content-wrap {

                .home {
                    .section-heading {
                        .section-title {
                            height   : unset;
                            font-size: 26px !important;

                            .title {
                                &::after {
                                    display: none;
                                }

                                &::before {
                                    display: none;
                                }
                            }

                            .extra-box {
                                display: none;
                            }
                        }
                    }
                }

                .home-counter {
                    padding: 0px 0px 50px 0px;
                    margin : 10px 0px !important;

                    .container {
                        .wrap-des-company {

                            .block-des {
                                margin-top: 15px;
                            }
                        }

                        .block-home-counter {
                            position  : relative;
                            padding   : 0px;
                            background: unset;
                            max-width : unset;
                            bottom    : 0px;

                            .home-counter-item {
                                .home-counter-number {
                                    font-size  : 32px;
                                    font-weight: bold;
                                }

                                .home-counter-border {
                                    display: block;
                                }

                                .home-counter-text {}
                            }
                        }
                    }

                }
            }
        }
    }
}